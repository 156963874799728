.list-container {
  padding: 28px 40px 0;

  .k-grid-header-wrap {
    .k-table {
      width: 100%;
      border-top: 1px solid var(--c-blue-grey);
      border-bottom: 1px solid var(--c-blue-grey);
      background: var(--c-lightblue-grey);

      thead {
        tr {
          th {
            font-family: var(--font-family);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.75rem;
            font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
            color: var(--c-darkblue);
            text-align: left;
            padding: 11px 0;
            transition: .3s ease-in-out;

            &.filter {
              width: 120px;
            }

            &.actions {
              width: 140px;
            }

            &.productname {
              width: calc(50% - 260px);
              cursor: pointer;

              &:hover, &:focus, &:active {
                 color: var(--c-darkblue-hover);
              }
            }

            &.supplier {
              width: 20%;
            }

            &.CMR {
              width: 10%;

              img {
                width: 20px;
              }
            }

            &.mpznumber {
              width: 20%;
              cursor: pointer;

              &:hover, &:focus, &:active {
                color: var(--c-darkblue-hover);
              }
            }

            .k-cell-inner {
              .k-link {
                display: flex;
                align-items: center;


                .k-icon {
                  display: inline-block;
                  position: relative;

                  &::before {
                    width: 12px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(50%, -16px);
                  }

                  &.k-i-sort-asc-small::before {
                    content: url("../../../../../public/Icons/sortIconAsc.svg");
                  }

                  &.k-i-sort-desc-small::before {
                    content: url("../../../../../public/Icons/sortIconDesc.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .k-grid-container {
    .k-grid-content {
      position: relative;

      .k-loading-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--c-white);
      }
    }

    .k-table {
      width: 100%;

      tbody {
        tr {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.5px;
            background: var(--c-blue-grey);
          }

          &:last-child::after {
            display: none;
          }

          td {
            font-family: var(--font-family);
            font-size: 0.75rem;
            font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
            color: var(--c-black-darkgrey);
            text-align: left;
            padding: 21px 0;
            transition: .3s ease-in-out;

            &.filter {
              width: 120px;
            }

            &.actions {
              width: 140px;
            }

            &.productname {
              width: calc(50% - 260px);

              button {
                text-align: left;
              }
            }

            &.supplier {
              width: 20%;
            }

            &.CMR {
              width: 10%;

              img {
                width: 30px;
              }
            }

            &.mpznumber {
              width: 20%;
            }
          }
        }
      }
    }

    .error-message {
      display: flex;
      gap: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__content {
        font-size: 1rem;
        color: var(--c-black-darkgrey);
        width: 300px;
        text-align: center;
      }
    }
  }

  .k-pager {
    display: flex;
    justify-content: center;
    padding-bottom: 53px;

    .k-dropdown-list {
      display: none;
    }

    .k-pager-numbers-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .k-pager-numbers {
        display: flex;
        gap: 5px;

        .k-button {
          background: unset;
          border: unset;
          cursor: pointer;
          color: var(--c-black-darkgrey);
          font-size: 0.75rem;
          font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          line-height: 1;
          transition: .3s ease-in-out;

          &.k-selected {
            background-color: var(--c-darkblue);
            color: var(--c-white);
            font-weight: 600;
          }


          &:hover {
            background-color: var(--c-lightblue);

            &.k-selected {
              background-color: var(--c-darkblue);
            }
          }
        }
      }


      .k-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .k-pager-nav {
        appearance: none;
        background: unset;
        width: 38px;
        height: 38px;
        border: 1px solid var(--c-blue-grey);
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
          border-color: var(--c-blackgrey);
        }

        span {
          &::before, &::after {
            display: block;
            width: 5px;
          }

          &.k-i-caret-alt-right::before {
            content: url("../../../../../public/Icons/arrowRight.svg");
          }

          &.k-i-caret-alt-left::before {
            content: url("../../../../../public/Icons/arrowRight.svg");
            transform: rotate(180deg);
          }
        }

        &.k-disabled {
          cursor: not-allowed;
          border-color: var(--c-lightblue-grey);
          background-color: var(--c-lightblue-grey);

          span {
            &::before, &::after {
              opacity: .3;
            }
          }
        }


        &.k-pager-first span {
          &::before {
            transform: rotate(180deg);
          }

          &::after {
            transform: rotate(180deg);
          }
        }

      }
    }

    .k-pager-info {
      display: none;
    }

    .k-picker {
      display: none !important //Override the default kendo styles;
    }

    .k-button span {
      display: inline-block;
      height: 15px;
      width: 15px;

      svg {
        fill: var(--c-darkblue);
      }
    }
  }
}

