.manuals {
  padding: 40px;

  &__content-top {
    border-top: 1px solid var(--c-blackgrey);
    padding-top: 40px;
  }

  &__content-bottom {
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
    margin-top: 36px;
  }

  &__header {
    margin: 0;
    font-size: 2.5rem;
  }

  &__text {
    margin: 32px 0;
    max-width: 500px;

    &--error-msg {
      font-size: 1rem;
      font-weight: 500;
      color: var(--c-blackgrey);
    }
  }

  &__input-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 760px;
  }
}
