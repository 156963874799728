.header-seperation-bar {
  width: 100%;
  display: flex;
  justify-content: center;

  &__line {
    width: calc(100% - 80px);
    margin-top: 40px;
    border-bottom: 1px solid var(--c-blackgrey);
  }
}
