@import '../../../../Shared/Styling/Mixins';

.substances-register-detail-information {
  padding: 10px;

  @include breakpoint('tablet') {
    padding: 40px;
  }

  &__button-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .information-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}