.checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 470px;
  width: 100%;

  &__label {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    font-weight: 600;
  }

  &__checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    min-width: calc(100% - 24px);
    max-width: calc(100% - 24px);
    max-height: 100px;
    padding: 12px;
    border: 1px solid var(--c-blue-grey);
    border-radius: 6px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--c-blue);
      border-radius: 6px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
        background: var(--c-blue-grey);
      }
    }
  }
}