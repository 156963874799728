.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  &__logo {
    width: 100%;
  }

  &__form {
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px 32px;
    border-radius: 20px;
    background-color: var(--c-lightblue);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    .stripped-button {
      padding: 0;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__error {
    display: block;
    color: var(--c-red);
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 30px;
    overflow: hidden;

    svg {
      transform: translateX(-25%);
    }
  }
}
