.form-field {
  &--wide-upload {
    max-width: 470px;
  }

  &__upload-container {
    display: flex;
    gap: 12px;
    margin-top: 8px;

    .hidden {
      display: none;
    }

    .upload-field {
      cursor: pointer;
      width: 100%;
      padding: 12px 16px;
      background: var(--c-lightblue);
      border: 1px solid var(--c-blue-grey);
      border-radius: 6px;
      transition: .3s ease-in-out;
      font-size: 0.75rem;
      font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

      span {
        width: 100%;
        height: 16px;
        display: block;
        overflow: hidden;
      }
    }
  }

  &__label {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    font-weight: 600;
  }

  &__error-message {
    display: block;
    min-width: 100%;
    margin: 8px 0;
    font-size: 1rem;
    font-weight: 500;
    color: var(--c-red);
  }

  &__success-message {
    display: block;
    min-width: 100%;
    margin: 8px 0;
    font-size: 1rem;
    font-weight: 500;
    color: var(--c-green);
  }

  &__image-container {
    max-width: 250px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
    background: var(--c-lightblue);
    border-radius: 6px;
    transition: .3s ease-in-out;
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  &__filename-container {
    margin-top: 10px;

    a {
      color: var(--c-darkblue-grey);
      font-size: 0.75rem;
      font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
      font-weight: 600;
      text-decoration: none;
      transition: .3s ease-in-out;

      &:hover {
        color: var(--c-darkblue-hover);
      }
    }
  }
}
