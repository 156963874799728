.form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 470px;

  &__small {
    max-width: 270px;
  }

  &__large {
    max-width: 370px;
  }

  &__extra-large {
    max-width: 470px;
  }

  &__x-small {
    max-width: 170px;
  }

  &__full {
    width: 100%;
    max-width: 100%;
  }

  &__label {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    font-weight: 600;
  }

  &__input {
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
    max-width: calc(100% - 32px);
    padding: 11px 16px;
    border: 1px solid var(--c-blue-grey);
    border-radius: 6px;
    transition: .3s ease-in-out;
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

    &:focus {
      border-color: var(--c-darkblue);
      background: var(--c-blue-transparent);
      outline: none;
    }

    &::placeholder {
      color: var(--c-blackgrey);
      font-family: var(--font-family);
      font-size: 0.75rem;
      font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    }

    &--textarea {
      height: 120px;
      font-family: var(--font-family);
    }
  }
}