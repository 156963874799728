.error {
  padding: 40px;

  &__content {
    border-top: 1px solid var(--c-blackgrey);
    padding-top: 40px;

  }

  &__header {
    margin: 0;
    font-size: 4rem;

  }

  &__text {
    margin-top: 0;
    max-width: 500px;

    &--error-msg {
      font-size: 1rem;
      font-weight: 500;
      color: var(--c-blackgrey);
    }
  }
}