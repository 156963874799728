.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.concept-name {
    width: calc(60% - 400px);
  }

  &.concept-date {
    width: 20%;
  }

  &.concept-creator {
    width: 20%;
  }

  &.concept-actions {
    width: 200px;
  }
}
