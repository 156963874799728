.content-card {
  background: unset;
  border: 1px solid var(--c-blue-grey);
  border-radius: 6px;
  padding: 24px;
  text-align: left;
  width: calc((100% / 3) - 24px);
  cursor: pointer;
  position: relative;
  transition: .3s ease-in-out;
  text-decoration: none;
  color: var(--c-black);

  h4 {
    margin: 0;
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
  }

  p {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    margin: 8px 0 12px;
  }

  img {
    position: absolute;
    right: 24px;
    bottom: 24px;
    width: 12px;
    transition: .3s ease-in-out;
  }

  &:hover {
    background: var(--c-blue-transparent);

    img {
      right: 20px;
    }
  }
}
