.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.symbolTitle {
    width: 200px;
  }

  &.symbolType {
    min-width: calc(100% - 600px);
  }

  &.symbolCell {
    width: 200px;
    align-items: center;
    gap: 10px;

    img {
      max-width: 75px;
      width: 100%;
    }
  }

  &.symbolActions {
    width: 200px;
  }
}
