.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.organisationName {
    width: calc(100% - 200px);

    .stripped-button {
      width: 100%;
      text-align: left;
    }
  }

  &.organisationActions {
    width: 200px;
  }
}

.itemStatus {
  width: 80px;
}
