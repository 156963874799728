@import '../../Styling/Mixins';

.title-with-searchbar {
  padding: 10px 30px 0;

  @include breakpoint('tablet') {
    padding: 40px 40px 0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    display: flex;
    align-items: center;

    h1 {
      margin: 6px 0;
      font-size: 1.5rem;
      font-size: clamp(1.5rem, 1.4rem + 0.5vw, 2rem);
      font-weight: 600;
    }
  }

  &__back-button {
    background: unset;
    border: unset;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 6px;
    transform: translateX(0);
    transition: .3s ease-in-out;

    img {
      width: 22px;
    }

    &:hover {
      transform: translateX(-5px);
    }
  }


  &__searchbar {
    display: flex;
    gap: 12px;

    .searchbar-wrapper {
      width: 300px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      background: var(--c-white);
      border: 1px solid var(--c-darkgrey-blue);
      padding: 12px 16px;

      button {
        padding: 8px 16px;
      }

      img {
        width: 20px;
        position: relative;
      }

      input {
        width: 100%;
        border: unset;
        background: unset;
        padding-left: 16px;
        font-size: 0.75rem;
        font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
        font-family: var(--font-family);
        color: var(--c-black);

        &:focus {
          outline: unset;
        }
      }
    }
  }
}