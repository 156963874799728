@import "../../../Shared/Styling/Mixins";

.side-dashboard {
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  min-height: calc(100vh - 84px);
  background-color: var(--c-lightblue);
  padding: 40px 0;
  transition: .3s ease-in-out;
  display: none;

  @include breakpoint('tablet') {
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 48px 0 40px;

    h3 {
      opacity: 0;
      font-size: 1rem;
      font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
      margin: 0;
      font-weight: 600;
      transition: .5s ease-in-out;
    }

    button.toggle-side-dashboard {
      background-color: var(--c-lightblue);
      position: absolute;
      right: 0;
      top: 36px;
      border: unset;
      cursor: pointer;
      padding: 10px 48px 10px 10px;
      margin-left: auto;

      img {
        width: 18px;
        height: auto;
        transform: rotate(180deg);
        transition: .3s ease-in-out;
      }
    }
  }

  &__content {
    min-width: 191px;
    margin-top: 55px;
    padding: 0 48px 0 40px;
    opacity: 0;
    transition: .3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100vh - 67px - 180px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--c-blue);
      border-radius: 10px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
        background: var(--c-blue-grey);
      }
    }

    .last-modified-date {
      font-size: 0.625rem;
      font-size: clamp(0.625rem, 0.575rem + 0.25vw, 0.875rem);
      color: var(--c-darkblue-grey);
    }
  }

  &__footer {
    min-width: 191px;
    position: absolute;
    left: 40px;
    bottom: 40px;
    opacity: 0;
    transition: .3s ease-in-out;
    background: var(--c-lightblue);
  }

  &--active {
    width: 275px;

    .side-dashboard__header {
      h3 {
        opacity: 1;
      }

      button.toggle-side-dashboard {
        img {
          transform: rotate(0deg);
        }
      }
    }

    .side-dashboard__content {
      opacity: 1;
    }

    .side-dashboard__footer {
      opacity: 1;
    }
  }
}
