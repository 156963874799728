// Define the breakpoints
$breakpoints: (
  'mobile': 576px,
  'tablet': 768px,
  'laptop': 992px,
  'desptop': 1200px
);

// Mixin for the breakpoints
@mixin breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint: #{$name}.";
  }
}