.filter-bar {
  display: flex;
  align-items: flex-end;
  padding: 48px 40px 0;

  &__filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    p {
      margin: 0 10px 0 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;

    p {
      margin: 0;
      white-space: nowrap;
    }
  }
}