.modal {
  &__background {
    backdrop-filter: blur(5px);
    background-color: var(--c-darkblue-transparent);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(90% - 64px);
    max-width: 440px;
    max-height: 90%;
    background-color: var(--c-white);
    border-radius: 10px;
    z-index: 10;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    svg {
      margin: auto;
    }


    h2,
    p {
      margin: 0;
      text-align: center;
      line-height: 1.2;
    }

    h2 {
      font-size: 1.25rem;
      font-size: clamp(1.25rem, 1.15rem + 0.5vw, 1.75rem);

    }

    p {
      font-size: 0.9rem;
      font-size: clamp(0.9rem, 0.855rem + 0.225vw, 1.125rem);
      opacity: 0.6;

      &.subtext {
        margin-top: 10px;
        font-size: 0.75rem;
        font-size: clamp(0.75rem, 0.7375rem + 0.0625vw, 0.8125rem);
        line-height: 1.5;
        padding: 0 15px;
      }
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;

    img {
      width: 13px;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

}