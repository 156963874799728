.pdf-view {
  padding: 40px;

  &__content-top {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &--bordered {
      padding-top: 40px;
      border-top: 1px solid var(--c-blackgrey);
    }

    h1 {
      margin: 0;
    }
  }

  &__content-middle {
    display: none;
  }

  &__content-bottom {
    margin-top: 32px;
    padding: 10px;
    height: calc(100vh - 315px);
    overflow-y: auto;
    background: var(--c-white-grey);
    border-radius: 4px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--c-blue);
      border-radius: 6px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
        background: var(--c-blue-grey);
      }
    }
  }
}

.react-pdf {
  &__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 1em;

    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }

  &__message {
    padding: 20px;
    color: white;
  }
}
