.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  &__logo {
    width: 100%;
  }

  &__login-screen {
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px 32px;
    border-radius: 20px;
    background-color: var(--c-lightblue);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &__error {
    display: block;
    color: var(--c-red);
    padding-top: 8px;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 30px;
    overflow: hidden;

    svg {
      transform: translateX(-25%);
    }
  }
}
