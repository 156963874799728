.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.userName {
    width: 20%;
  }

  &.userEmail {
    width: 20%;
  }

  &.userOrganisation {
    width: 20%;
  }

  &.userRole {
    width: calc(20% - 200px);
  }

  &.userActions {
    width: 200px;
  }
}
