.form-field {
  &__table {
    max-width: 670px;
    margin: 30px 0;

    &--components {
      max-width: 880px;

      .code-cell {
        display: inline;
        white-space: nowrap;
      }
    }
  }

  &__table-grid {
    width: 100%;
  }

  &__table-grid-row {
    position: relative;
    padding: 12px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--c-blue-grey);
    }
  }

  &__table-grid-cell {
    padding: 12px;

    span {
      width: 75%;
      display: block;
    }

    &--delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;

      button {
        background: unset;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: .3s ease-in-out;

        svg {
          height: 15px;
        }
      }
    }

  }

  &__add {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    align-items: flex-end;

    .button {
      height: 44px;
      margin-left: auto;
    }
  }

  &__message {
    margin-top: 8px;
    color: var(--c-red);
  }
}
