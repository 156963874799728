.navigation-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;

  &__image-container {
    background: var(--c-white);
    max-width: 49px;
    max-height: 49px;
    width: 100%;
    height: 49px;
    border-radius: 50%;
    border: 1px solid var(--c-darkgrey-blue);
  }

  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__name {
    p {
      margin: 0;
      font-size: 0.75rem;
      font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

      b {
        font-weight: 600;
      }
    }
  }
}
