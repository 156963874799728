.location-selector {
  strong {
    display: inline-block;
    margin-bottom: 8px;
  }

  &__selects {
    display: flex;
    gap: 8px;
  }
}
