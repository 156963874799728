@import '../../../../Shared/Styling/Mixins';

.ehbo-information {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  &__card {
    flex-basis: calc(100%);

    @include breakpoint('tablet') {
      flex-basis: calc(50% - 32px);
    }

    background: var(--c-white-grey);
    padding: 16px 32px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      display: inline-block;
      width: 250px;
    }
  }

  &__symbols {
    display: flex;
    gap: 8px;

    img {
      height: 50px;
    }
  }

  .information-row__list {
    margin-bottom: 16px;
  }

  .information-row__text {
    display: block;

    &--title {
      font-weight: bold;
    }
  }
}
