@import '../../Styling/Mixins';

nav {
  background-color: var(--c-lightblue);
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: visible;
  width: 200px;

  @include breakpoint('laptop') {
    width: 300px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &.navigation-content__wrapper--is-small {
    width: 0;
    padding: 8px;

    .navigation-content__button-wrapper {
      button {
        position: fixed;
        top: 4px;
        left: 4px;

        img {
          transform: rotate(0);
        }
      }
    }
  }

  &::-webkit-scrollbar-thumb {
    background: var(--c-blue);
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background: var(--c-blue-grey);
    }
  }

  .navigation-content {
    &__button-wrapper {
      display: flex;
      justify-content: right;
      margin-bottom: 32px;

      button {
        padding: 8px 12px;

        img {
          transition: .3s;
          transform: rotate(180deg);
        }
      }
    }

    &__top {
      .company-logo {
        margin-bottom: 50px;

        img {
          height: auto;
          display: block;
          max-height: 76px;
          width: 100%;

          @include breakpoint('laptop') {
            max-width: calc(100% - 32px);
            margin-left: 16px;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
          width: 100%;
          margin-bottom: 8px;
          transition: 0.3s ease-in-out;

          a,
          button {
            text-decoration: none;
            display: flex;
            align-items: center;
            width: 100%;
            text-align: left;
            padding: 10px 16px;
            border-radius: 6px;
            background: var(--c-lightblue);
            border: none;
            color: var(--c-black);
            cursor: pointer;
            transition: 0.3s ease-in-out;

            img.menu-icon {
              margin-right: 8px;
              width: 16px;
              height: auto;
            }

            span {
              font-family: var(--font-family);
              font-weight: 600;
              font-size: 0.75rem;
              font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
              transition: all 0.2s ease-in-out;
            }

            &.menu-link {
              &:hover {
                background: var(--c-blue);
              }
            }

            &.sub-menu-toggle-trigger {
              img.toggle-arrow {
                margin-left: auto;
                width: 12px;
                height: auto;
                transform: rotate(0deg);
                transition: all 0.2s ease-in-out;
              }

              &.active {
                img.toggle-arrow {
                  transform: rotate(180deg);
                }
              }
            }
          }

          a {
            width: calc(100% - 32px);
          }

          ul.sub-menu {
            li {
              width: 100%;
              margin-bottom: 0;

              a,
              button {
                .sub-menu-icon {
                  margin: 0 10px 0 24px;
                  height: 10px;
                  width: 10px;
                  border-radius: 2px;
                  background: var(--c-darkblue);
                }

                span {
                  font-family: var(--font-family);
                  font-weight: 300;
                  font-size: 0.75rem;
                  font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
                  transition: all 0.2s ease-in-out;
                }

                &.menu-link {
                  &:hover {
                    background: var(--c-blue);
                  }
                }
              }
            }
          }
        }
      }
    }

    &__bottom {
      margin-top: 40px;
      padding: 0 16px 24px;
    }
  }
}
