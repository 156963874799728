@import '../../../Shared/Styling/Mixins';

.tabbar {
  padding: 10px 0 10px 10px;

  @include breakpoint('tablet') {
    padding: 40px;
  }


  &__items {
    border-bottom: 1px solid var(--c-blackgrey);
    display: flex;
    gap: 20px;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    .tabbar-button {
      position: relative;
      background: unset;
      border: none;
      padding: 0 5px 9px;
      font-size: 0.75rem;
      font-size: clamp(0.75rem, 0.675rem + 0.375vw, 1.125rem);
      font-family: var(--font-family);
      cursor: pointer;
      transition: .3s ease-in-out;
      white-space: nowrap;

      &::before {
        display: block;
        content: attr(title);
        font-weight: 600;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &::after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        opacity: 0;
        background: var(--c-green);
        transition: .3s ease-in-out;
      }

      &:focus-visible, &:focus, &:active {
        outline: none;
      }
    }

    &--active, &:hover {
      .tabbar-button {
        font-weight: 600;
        color: var(--c-green);

        &::after {
          opacity: 1;
        }
      }
    }
  }

}