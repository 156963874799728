.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.packagingGroupName {
    width: calc(100% - 200px);
  }

  &.packagingGroupActions {
    width: 200px;
  }
}
