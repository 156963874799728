.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.componentName {
    width: 25%;
  }

  &.casNumber {
    width: calc(75% - 200px);
  }

  &.componentActions {
    width: 200px;
  }
}
