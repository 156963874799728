.data-card {
  width: calc(100% - 40px);
  padding: 26px 20px;
  background-color: var(--c-white);
  border-radius: 10px;

  &__number {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
    font-size: clamp(1.5rem, 1.3rem + 1vw, 2.5rem);
    color: var(--c-green);
  }

  &__explanation {
    text-align: center;
    font-size: 0.625rem;
    font-size: clamp(0.625rem, 0.575rem + 0.25vw, 0.875rem);
    color: var(--c-black-darkgrey);
    margin-top: 8px;
    margin-bottom: 0;
  }
}