@import '../../../Shared/Styling/Mixins';

.collapsable-information-field {
  &__toggle-bar {
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--c-lightblue);
    border: unset;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    img.toggle-arrow {
      width: 11px;
      transform: rotate(0deg);
      transition: .3s ease-in-out;
    }

    &--active {
      img.toggle-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__information {
    list-style: none;
    padding: 0;
    margin: 10px;

    @include breakpoint('tablet') {
      margin: 24px 24px 43px;
    }

    display: grid;
    grid-template-columns: 70% 30%;

    &--wide {
      grid-template-columns: 100%;
    }


    ul {
      width: 100%;
      padding: 0;
      list-style: none;
    }

    .information-row {
      &__text {
        @include breakpoint('laptop') {
          display: flex;
        }

        max-width: 100%;
        gap: 40px;
        margin-bottom: 14px;
        font-size: 0.75rem;
        font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
        white-space: pre-line;

        &--title {
          display: block;
          font-weight: 600;
          width: 210px;
        }

        &--description {
          display: block;

          @include breakpoint('laptop') {
            width: calc(100% - 250px);
          }
        }
      }

      &__list {
        @include breakpoint('laptop') {
          width: calc(100% - 250px);
        }
      }

      &__danger-symbols {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 10px;

        img {
          max-width: 40px;
          max-height: 40px;
        }

        @include breakpoint('laptop') {
          img {
            max-width: 80px;
            max-height: 80px;
          }
        }

        &--word {
          font-weight: 600;
          width: 100%;
          text-align: right;
        }
      }
    }

    &--form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}