.button {
  border: unset;
  background: unset;
  font-family: var(--font-family);
  font-weight: 600;
  border-radius: 10px;
  padding: 12px 24px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  transition: .3s ease-in-out;
  width: fit-content;

  img {
    width: 16px;
    height: auto;
  }

  &--bordered {
    border: 1px solid var(--c-darkblue);
    color: var(--c-darkblue);
    transition: .3s ease-in-out;
    box-shadow: 0 0 0 0 var(--c-darkblue);

    &:hover {
      box-shadow: 0 0 0 1px var(--c-darkblue);
    }
  }

  &--bordered-green {
    border: 1px solid var(--c-green);
    color: var(--c-green);
    transition: .3s ease-in-out;
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    box-shadow: 0 0 0 0 var(--c-green);

    &:hover {
      box-shadow: 0 0 0 1px var(--c-green);
    }
  }

  &--solid {
    background: var(--c-green);
    color: var(--c-white);
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

    &:hover {
      background: var(--c-darkgreen);
    }
  }

  &--solid-blue {
    background: var(--c-darkblue);
    color: var(--c-white);
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);

    &:hover {
      background: var(--c-darkblue-hover);
    }
  }

  &--auto {
    width: fit-content;
  }

  &--full {
    width: 100%;
  }

  &:focus-visible,
  &:active, &:focus {
    outline: none;
  }
}
