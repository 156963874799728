.edit-add-grid {
  padding: 40px;

  &__content {
    border-top: 1px solid var(--c-blackgrey);
    padding-top: 40px;
  }

  &__header {
    margin: 0;
    font-size: 2.5rem;
  }

  &__text {
    margin: 32px 0;
    max-width: 500px;

    &--error-msg {
      font-size: 1rem;
      font-weight: 500;
      color: var(--c-blackgrey);
    }
  }

  button.button--solid {
    margin-top: 22px;
  }

  &__input-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 760px;
    margin: 32px 0 0;
  }

  &__error-message {
    display: block;
    min-width: 100%;
    margin: 0 0 -22px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--c-red);
  }

  &__success-message {
    display: block;
    min-width: 100%;
    margin: 0 0 -22px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--c-green);
  }
}