.substances-register-edit-form {
  padding: 48px 40px 80px;
  position: relative;

  &__button-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .information-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-buttons {
    display: flex;
    width: calc(100% - 380px);
    gap: 10px;
    justify-content: flex-end;
    margin-top: 30px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 20px 40px;
    background: var(--c-white);
    border-top: 1px solid var(--c-blue-grey);
  }
}