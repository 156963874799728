.list-container .k-grid-header-wrap .k-table thead tr th,
.k-grid-container .k-table tbody tr td {
  &.hazardName {
    width: 25%;
  }

  &.hazardDescription {
    width: calc(75% - 200px);
  }

  &.hazardActions {
    width: 200px;
  }
}
