.share-substance {
  &__header {
    margin: 0;
    font-size: 2.5rem;
  }

  @media print {
    &__qr-code {
      padding: 60px;
    }
  }

  &__qr-code {
    margin-bottom: 32px;

    svg {
      max-width: 100%;
    }
  }
}