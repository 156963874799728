.form-field {
  &--wide {
    max-width: 465px;
  }

  &__label {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
    font-weight: 600;
  }

  .select-box {
    &__control {
      min-width: 120px;
      border-radius: 6px;
    }
  }
}