@import "Shared/Styling/Mixins";

@font-face {
  font-family: averta;
  src: url('../public/fonts/Averta.otf') format('opentype');
}

@font-face {
  font-family: averta;
  font-weight: 600;
  src: url('../public/fonts/AvertaSemibold.otf') format('opentype');
}

:root {
  // Color variables
  --c-white: #ffffff;
  --c-white-grey: #f9fafb;
  --c-blackgrey: #777776;
  --c-black-darkgrey: #1D1D1B;
  --c-black: #000000;
  --c-lightblue: #F6F8FA;
  --c-lightblue-grey: #F9FBFD;
  --c-blue: #D8E2EC;
  --c-blue-transparent: rgba(240, 243, 246, 0.4);
  --c-darkblue: #5776B0;
  --c-darkblue-hover: #456197;
  --c-darkblue-transparent: rgb(3, 24, 63, 0.3);
  --c-darkblue-grey: #50769B;
  --c-darkgrey-blue: #96A5B5;
  --c-blue-grey: #C3D0DC;
  --c-green: #39A935;
  --c-darkgreen: #2b8629;
  --c-red: #ff3939;
  --c-lightred: #ffb8b8;

  // Fonts
  --font-family: averta, roboto, sans-serif;
  --font-family-semibold: averta-semibold, roboto, sans-serif;
}

html {
  font-size: 16px;

  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);

    .app {
      display: flex;
    }


    .content-container {
      display: flex;
      transition: .3s ease-in-out;
      margin-left: 200px;
      width: calc(100% - 200px);

      @include breakpoint('laptop') {
        margin-left: 300px;
        width: calc(100% - 300px);
      }

      &__with-sidemenu {
        width: calc(100% - 406px);

        &--active {
          width: calc(100% - 575px);
        }
      }

      section {
        width: 100%;

        &.variable-width {
          width: auto;
        }
      }
    }

    .navigation-content__wrapper--is-small + .content-container {
      margin-left: 16px;
      width: calc(100% - 16px);

      @include breakpoint('tablet') {
        width: calc(100% - 100px);
      }
    }

    .select-box {
      &__control {
        border: 1px solid var(--c-blue-grey);
        border-radius: 6px;
        padding: 0 16px;
        min-width: 200px;

        &:hover, &--is-focused {
          border-color: var(--c-darkblue) !important;
          background: var(--c-blue-transparent) !important;
          box-shadow: unset !important;
        }
      }

      &__value-container {
        padding: 6px 8px 6px 0;
        max-height: 47px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--c-blue);
          border-radius: 6px;
          cursor: pointer;
          transition: .3s ease-in-out;

          &:hover {
            background: var(--c-blue-grey);
          }
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        padding: 0;
      }

      &__placeholder {
        color: var(--c-black-darkgrey);
      }

      &__multi-value {
        background: var(--c-darkblue);
        color: var(--c-white);
        border-radius: 6px;
        padding: 4px;

        &__remove {
          cursor: pointer;
          color: var(--c-white);
          margin-left: 8px;
          border-radius: 50%;

          &:hover {
            background: var(--c-lightblue);
            color: var(--c-darkblue)
          }
        }
      }

      &__multi-value__label {
        color: var(--c-white);
      }
    }
  }

  .stripped-button {
    border: unset;
    background: unset;
    cursor: pointer;
    font-size: 1rem;
    color: var(--c-black-darkgrey);
    text-decoration: none;
    padding: 0 4px;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .absolute-loading-container {
    position: absolute;
    z-index: -1;
    margin: 0 auto;
  }
}

